// React
import React from "react";
import { Card, CardBody } from "reactstrap";

const AreaChartCard = ({ topTitle, sections }) => {
  const renderSection = (title, subtitle) => (
    <div className="detail-card-section">
      <div className="detail-card-title">{title}</div>
      <div className="detail-card-subtitle">{subtitle}</div>
    </div>
  );

  return (
    <Card
      style={{
        backgroundColor: "#333333",
        color: "lightgrey",
        borderRadius: "4px",
        border: "1px solid lightgrey",
      }}
    >
      <CardBody className="detail-card-cardbody">
        {topTitle && (
          <div
            className="detail-card-top-title"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              borderBottom: "2px solid lightgrey",
              paddingBottom: "10px",
              marginBottom: "10px",
            }}
          >
            {topTitle}
          </div>
        )}
        {sections.map((section, index) => (
          <div key={index}>
            {renderSection(section.title, section.subtitle)}
            {index < sections.length - 1 && <hr className="detail-hr" />}
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default AreaChartCard;
