// React
import React from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Rss, AlertTriangle, Edit2, AlertCircle } from "react-feather";
// Styles
import "./styles/DeviceList.css";
import "./styles/Acordeon.css";
//Components
import ColorDot from "./components/ColorDot";
import ProgressBarElement from "./components/ProgressBarElement";
import FormatNumber from "./components/FormatNumber";
import AreaChartCard from "./components/AreaChartCard";
import PieChartCard from "./components/PieChartCard";
import CustomChart from "./components/CustomChart";
// Imports
import { DateTime } from "luxon";

const StarlinkDeviceCard = ({
  Nivel,
  KitNumber,
  KitCliente,
  KitClienteFinal,
  KitName,
  ServiceLineNumber,
  AccountNumber,
  percentage,
  totalPlan,
  signal,
  alert,
  TitleBajada,
  TitleSubida,
  downlinkShorLimit,
  dataKeySubida,
  dataKeySubida_2,
  GbData,
  StartDate,
  EndDate,
  TitlePriority,
  MinPriority,
  MaxPriority,
  LastPriority,
  LimitePriority,
  handleClickUpdate,
  isExpanded,
  handleClick,
  //
  PPrioritario,
  PEstandar,
  //DownlinkShort
  downlinkShort,
  downlinkShortMin,
  downlinkShortMax,
  downlinkShortAvg,
  dataKeyBajada,
  // Uplink
  uplinkThroughput,
  uplinkThroughputDataKey,
  uplinkThroughputLimit,
  uplinkThroughputMin,
  uplinkThroughputMax,
  uplinkThroughputAverage,
  // Downlink
  downlinkThroughput,
  downlinkThroughputDataKey,
  downlinkThroughputLimit,
  downlinkThroughputMax,
  downlinkThroughputMin,
  downlinkThroughputAverage,
  // PingLatency
  pingLatency,
  pingLatencyDataKey,
  pingLatencyLimit,
  pingLatencyMin,
  pingLatencyMax,
  pingLatencyAverage,
  // Snr
  snrAvg,
  snrAvgDataKey,
  snrAvgLimit,
  snrAvgMin,
  snrAvgMax,
  snrAvgAverage,
  // PingDrop
  pingDropRate,
  pingDropRateDataKey,
  pingDropRateLimit,
  pingDropRateMin,
  pingDropRateMax,
  pingDropRateAverage,
  // ObstructionTime
  obstructionTime,
  obstructionTimeDataKey,
  obstructionTimeLimit,
  obstructionTimeMin,
  obstructionTimeMax,
  obstructionTimeAverage
}) => {
  const nivelDefault = localStorage.getItem('nivel_default')
  
  const cardDataMensual = {
    planTitle: 'Plan de Servicio Mensual',
    planSubtitle: `Datos Prioritarios - ${totalPlan} GB`,
    dataTitle: 'Nombre del Equipo',
    dataSubtitle: `${KitName}`,
    idTitle: 'Número de Kit',
    idSubtitle: `${KitNumber}`,
    serialTitle: 'Número de Cuenta',
    serialSubtitle: `${AccountNumber}`
  };

  const exceedsGB = Math.max(0, percentage - totalPlan); // changes
  const FechaInicio = DateTime.fromISO(StartDate).toFormat('MM/dd');
  const FechaTermino= DateTime.fromISO(EndDate).toFormat('MM/dd');
  

  const cardDataExtraMensual = {
    planTitle: 'Promedio Diario Datos Prioritarios', planSubtitle: `${FormatNumber(PPrioritario)} GB`,
    dataTitle: 'Promedio Dirario Datos Estándar', dataSubtitle: `${FormatNumber(PEstandar)} GB`,
    idTitle: 'Datos Prioritarios Usados', idSubtitle: `${FormatNumber(percentage-exceedsGB)} GB`,
    serialTitle: 'Datos Estándar Usados', serialSubtitle: `${FormatNumber(Math.round(exceedsGB))} GB`
  };

  const areaChartDataSubida = [
    { title: 'Promedio esta hora', subtitle: `${uplinkThroughputAverage}` },
    { title: 'Mayor Uso', subtitle: `${uplinkThroughputMax}`},
  ];

  const areaChartDataBajada = [
    { title: 'Promedio esta hora', subtitle: `${downlinkThroughputAverage}` },
    { title: 'Mayor Uso', subtitle: `${downlinkThroughputMax}`},
  ];

  return (
    <Card className="border-0" style={{ backgroundColor: "#2c2c2c" }}>
      <header className="ms-2 me-2 mt-2 mb-0">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          {/* Línea 1: Nombres */}
          <h4 style={{ textAlign: "center", color: "lightgray", flex: 1 }}>
            {KitCliente} / {KitClienteFinal} / {KitName}
          </h4>
          
          {/* Línea 2: Estado y Botones */}
          <div className="d-flex flex-wrap align-items-center gap-3">
            <div className="d-flex align-items-center gap-2 flex-grow-1">
              {signal === true ? (
                <>
                  Online
                  <ColorDot color={"#00FF00"} />
                  <Rss size={20} color="lightgrey" className="main-panel-icon" />
                </>
              ) : signal === "warning" ? (
                <>
                  Alert
                  <ColorDot color={"#FFD700"} />
                  <AlertTriangle size={20} color="lightgrey" className="main-panel-icon" />
                </>
              ) : (
                <>
                  Offline
                  <ColorDot color={"#dc3545"} />
                  <Rss size={20} color="lightgrey" className="main-panel-icon" />
                </>
              )}
            </div>
            <div className="d-flex align-items-center gap-3 flex-shrink-0">
              {nivelDefault >= 2 && Nivel >= 2 ? (
                <Button className="ml-1" onClick={handleClickUpdate}>
                  <Edit2 size={15} strokeWidth={2} color="lightgrey" className="mb-1" />
                </Button>
              ) : (
                <Button className="ml-1 disabled-button" disabled>
                  <AlertCircle size={15} strokeWidth={2} color="lightgrey" className="mb-1" />
                </Button>
              )}
              <Button className="custom-button" onClick={handleClick}>
                {isExpanded ? 'Ocultar Detalles' : 'Ver Detalles'}
              </Button>
            </div>
          </div>
        </div>
      </header>
      <CardBody className="card-body-dispositivos m-0 p-0">
        <Row className="row-dispositivos">
          <Col className="col-dispositivos">
            <Card className="border-0" style={{ background: '#2c2c2c' }}>
              <CardBody className="m-0 py-1">
                <Row>
                  <Col md={3} xs={12} className="custom-font-size-8">
                    <strong>N° de Cuenta: </strong><span className="me-2">{AccountNumber}</span><br />
                    <strong>N° KIT: </strong><span>{KitNumber}</span><br />
                    <strong>N° de Linea de Servicio: </strong><span>{ServiceLineNumber}</span>
                    <div className="mt-2">
                      <ProgressBarElement percentage={percentage} totalPlan={totalPlan}/>
                    </div>
                  </Col>
                  <Col md={4} xs={12} className="text-center custom-font-size-8">
                    {/* <strong>Datos Facturación {FechaInicio}-{FechaTermino}:</strong>
                    <br />
                    <strong>MÍN: </strong>{MinPriority} GB 
                    <strong className="ms-3">MÁX: </strong>{MaxPriority} GB
                    <strong className="ms-3">ÚLTIMO: </strong>{LastPriority} GB */}
                    <CustomChart
                      data={GbData}
                      title={`Datos Facturación ${FechaInicio}-${FechaTermino}`}
                      subtitle={`MÍN: ${MinPriority} GB   MÁX: ${MaxPriority} GB   ÚLTIMO: ${LastPriority} GB`}
                      type="stackedBar"
                      dataKey={dataKeySubida}
                      dataKey_2={dataKeySubida_2}
                      stroke="#005a9e"
                      fill="#007acc"
                      yAxisUnit=""
                      yAxisDomain={[0, LimitePriority]}
                      height={200}
                      xAxisFontSize={10}
                    />
                  </Col>
                  <Col md={4} xs={12} className="text-center custom-font-size-8">
                    <CustomChart
                      title={TitleBajada}
                      subtitle={`MÍN: ${downlinkShortMin}   MÁX: ${downlinkShortMax}   PROM: ${downlinkShortAvg}`}
                      data={downlinkShort}
                      type="area"
                      dataKey={dataKeyBajada}
                      fill="#007acc"
                      yAxisUnit={dataKeyBajada}
                      height={200}
                      yAxisDomain={[0, downlinkShorLimit]}
                    />
                  </Col>
                </Row>
                <TransitionGroup>
                  {isExpanded && (
                    <CSSTransition
                      timeout={400}
                      classNames="accordion"
                    >
                      <Row className='p-3'>
                        <hr className="mt-2"/>
                        <Col xs={12} md={6}>
                          <PieChartCard data={cardDataMensual} />
                        </Col>
                        <Col xs={12} md={6}>
                          <PieChartCard data={cardDataExtraMensual} />
                        </Col>
                        <hr className="mt-3"/>
                        <Col xs={12} md={2}>
                          <AreaChartCard topTitle={TitleSubida} sections={areaChartDataSubida} />
                        </Col>
                        <Col md={4} xs={12} className="text-center custom-font-size-8">
                          <CustomChart
                            title={TitleSubida}
                            data={uplinkThroughput}
                            type="area"
                            dataKey={uplinkThroughputDataKey}
                            fill="#007acc"
                            yAxisUnit={'Mbps'}
                            height={200}
                            yAxisDomain={[0, uplinkThroughputLimit]}
                          />
                        </Col>
                        <Col xs={12} md={2}>
                          <AreaChartCard topTitle={"Downlink: "} sections={areaChartDataBajada} />
                        </Col>
                        <Col md={4} xs={12} className="text-center custom-font-size-8">
                          <CustomChart
                            title={"Downlink: "}
                            data={downlinkThroughput}
                            type="area"
                            dataKey={dataKeyBajada}
                            fill="#007acc"
                            yAxisUnit={dataKeyBajada}
                            height={200}
                            yAxisDomain={[0, downlinkThroughputLimit]}
                          />
                        </Col>
                        <hr className="mt-3"/>
                        <Col xs={12} md={3}>
                          <CustomChart
                            title={'Latencia:'}
                            subtitle={`MÍN: ${pingLatencyMin}   MÁX: ${pingLatencyMax}   PROM: ${pingLatencyAverage}`}
                            data={pingLatency}
                            type="area"
                            dataKey={pingLatencyDataKey}
                            stroke="#005a9e"
                            fill="#007acc"
                            yAxisUnit=""
                            yAxisDomain={[0, pingLatencyLimit]}
                            height={200}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <CustomChart
                            title={'Tasa caída de Ping:'}
                            subtitle={`MÍN: ${pingDropRateMin}   MÁX: ${pingDropRateMax}   PROM: ${pingDropRateAverage}`}
                            data={pingDropRate}
                            type="area"
                            dataKey={pingDropRateDataKey}
                            stroke="#005a9e"
                            fill="#007acc"
                            yAxisUnit={'ms'}
                            yAxisDomain={[0, 100]}
                            height={200}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <CustomChart
                            title={'Calidad de la señal:'}
                            subtitle={`MÍN: ${snrAvgMin}   MÁX: ${snrAvgMax}   PROM: ${snrAvgAverage}`}
                            data={snrAvg}
                            type="area"
                            dataKey={snrAvgDataKey}
                            stroke="#005a9e"
                            fill="#007acc"
                            yAxisUnit="%"
                            yAxisDomain={[0, 100]} // acá siempre es 100 por el porcentaje
                            height={200}
                          />
                        </Col>
                        <Col xs={12} md={3}>
                          <CustomChart
                            title={'Obstrucción:'}
                            subtitle={`MÍN: ${obstructionTimeMin}   MÁX: ${obstructionTimeMax}   PROM: ${obstructionTimeAverage}`}
                            data={obstructionTime}
                            type="area"
                            dataKey={obstructionTimeDataKey}
                            stroke="#005a9e"
                            fill="#007acc"
                            yAxisUnit="porcentaje"
                            yAxisDomain={[0, 100]} // también es porcentaje teóricamente 
                            height={200}
                          />
                        </Col>
                      </Row>
                    </CSSTransition>
                  )}
                </TransitionGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {signal === "warning" ? (
          <header className="dashboard-header-alert ms-2 me-2 mt-4">
            <div className="d-flex justify-content-start align-items-center">
              <span className="text-left" style={{ fontSize: '12px' }}>
                <AlertTriangle size={20} strokeWidth={2.5} color="lightgrey" className="me-2 mb-1" />
                <strong>Alerta: </strong>
                {alert}
              </span>
            </div>
          </header>
        ) : (
          <>{/* Blank */}</>
        )}
      </CardBody>
    </Card>
  );
};

export default StarlinkDeviceCard;
