// React
import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
// Components 
import ValidToken from '../components/ValidToken';

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (!ValidToken(token)) {
      navigate('/login');
    }
  }, [token, navigate]);

  if (!ValidToken(token)) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;