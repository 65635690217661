// React
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// Styles
import '../styles/Navbar.css';
import logo from '../logos/logo250.png'

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/Dashboard">StarManager Dashboard</Link>
        <a href="/Dashboard">
          <img src={logo} alt="StarLink Logo" className="navbar-logo-image" />
        </a>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
        <li>
          {isActive('/Dashboard') ? (
            <span className="active-link disabled">Gráficos</span>
          ) : (
            <Link to="/Dashboard">Gráficos</Link>
          )}
        </li>
        <li>
          <button className="update-btn" onClick={() => window.location.reload()}>Actualizar</button>
        </li>
        <li>
          <button className="logout-btn" onClick={handleLogout}>Cerrar Sesión</button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;