// React
import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

const PieChartCard = ({ data }) => {
  const renderSection = (title, subtitle) => (
    <div className="detail-card-section">
      <div className="detail-card-title">{title}</div>
      <div className="detail-card-subtitle">{subtitle}</div>
    </div>
  );

  return (
    <Card
      style={{
        backgroundColor: "#333333",
        color: "lightgrey",
        borderRadius: "4px",
        border: "1px solid lightgrey",
      }}
    >
      <CardBody className="text-center">
        <Row className="justify-content-center">
          <Col
            md={6}
            xs={12}
            className="text-center border-right d-none d-md-block"
            style={{ borderRight: "1px solid lightgrey" }}
          >
            {renderSection(data.planTitle, data.planSubtitle)}
          </Col>
          <Col md={6} xs={12} className="text-center">
            {renderSection(data.dataTitle, data.dataSubtitle)}
          </Col>
        </Row>
        <hr
          className="detail-hr"
          style={{ margin: "1rem 0", borderTop: "1px solid lightgrey" }}
        />
        <Row className="justify-content-center">
          <Col
            md={6}
            xs={12}
            className="text-center border-right d-none d-md-block"
            style={{ borderRight: "1px solid lightgrey" }}
          >
            {renderSection(data.idTitle, data.idSubtitle)}
          </Col>
          <Col md={6} xs={12} className="text-center">
            {renderSection(data.serialTitle, data.serialSubtitle)}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PieChartCard;
