// React
import React from 'react';
// Styles
import '../styles/LoadingScreen.css';

const LoadingScreen = () => {
  const screenStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#000000',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <div className="loading-screen" style={screenStyle}>
      <div className="loading-content" style={contentStyle}>
        <div className="loading-spinner"></div>
        <h1 className="loading-text">Cargando</h1>
      </div>
    </div>
  );
};

export default LoadingScreen;