// React
import React from "react";
import { BrowserRouter, Route, Navigate } from "react-router-dom";
import { ApmRoutes } from "@elastic/apm-rum-react";
// Core
import ProtectedRoute from "./core/ProtectedRoute";
import Login from "./core/Login";
// Imports
import { init as initApm } from "@elastic/apm-rum";
import DeviceList from "./DeviceList";

const apm = initApm({
  serviceName: "stacanto_front",
  serverUrl: process.env.REACT_APP_URL_APM,
  serviceVersion: "",
});

function App() {
  return (
    <BrowserRouter>
      <ApmRoutes>
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DeviceList />} />
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </ApmRoutes>
    </BrowserRouter>
  );
}

export default App;
