// React
import React from "react";

const Footer = () => (
  <div className="footer">
    <p>
      Copyright © 2024 Acanto S.A.{" "}
      {process.env.REACT_APP_VERSION
        ? ` - v${process.env.REACT_APP_VERSION}`
        : ""}
    </p>
  </div>
);

export default Footer;
