// React
import React from "react";

const ColorDot = ({ color }) => (
  <span
    style={{
      display: "inline-block",
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: color,
      marginRight: "0px",
      marginTop: '6px'
    }}
  >
  </span>
);

export default ColorDot;