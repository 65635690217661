// Imports
import axios from "axios";
import { useState } from "react";

const UseStoreKit = () => {
  const [nickname, setNickname] = useState("");
  const [clienteFinal, setClienteFinal] = useState("");
  const [cliente, setCliente] = useState("");

  const saveData = async (url, inputID, headers) => {
    try {
      const data = {
        cliente_final: clienteFinal,
        nickname: nickname,
        cliente: cliente,
      };

      const response = await axios.post(
        `${url}/api/service_line/${inputID}/modificar_etiquetas`,
        data,
        { headers }
      );

      const responseData = response.data.datos;
      console.log("Datos enviados correctamente", responseData);
      return responseData;
    } catch (error) {
      console.error("Error al enviar los datos", error);
      throw error;
    }
  };

  return {
    nickname,
    setNickname,
    cliente,
    setCliente,
    clienteFinal,
    setClienteFinal,
    saveData,
  };
};

export default UseStoreKit;
