// React
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Styles
import '../styles/Login.css';
// Axios
import axios from 'axios';
import Footer from './Footer';

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_URL_API;

  useEffect(() => {
    localStorage.removeItem('token');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      const response = await axios.post(url+'/auth/login', {
        username,
        password,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      const data = response.data;
      localStorage.setItem('token', data.datos.token);
      localStorage.setItem('nivel_default', data.datos.user_data.nivel_default);
      setIsLoading(false);
      navigate('/Dashboard'); // Redireccionar a Dashboard
    } catch (error) {
      console.error('Error:', error);
      // axios encapsula la respuesta del servidor en 'response', por lo que se debe verificar si 'error.response' está presente
      if (error.response && error.response.status === 401) {
        setError('Usuario o contraseña incorrecta');
      } else {
        setError('Error en la autenticación');
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="background-image"></div>
      <div className="login-form-container">
        <form onSubmit={handleSubmit} className="login-form">
          <h2 style={{color: 'lightgray'}}>Iniciar Sesión</h2>
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <label htmlFor="username">Usuario:</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Ingresando...' : 'Ingresar'}
          </button>
        </form>
        <Footer />
      </div>
    </div>
  );
}

export default Login;
