const ValidToken = (token) => {
  if (!token) return false;

  try {
    const [, payloadBase64] = token.split(".");
    const payload = JSON.parse(
      atob(payloadBase64.replace(/-/g, "+").replace(/_/g, "/"))
    );

    if (!payload.exp) {
      return false;
    }

    if (Date.now() >= payload.exp * 1000) {
      return false;
    }

    return true;
  } catch (e) {
    console.error("Error al validar el token:", e);
    return false;
  }
};

export default ValidToken;
