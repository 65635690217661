const CalculateSummaryData = (graphData) => {
  return Object.values(graphData).reduce(
    (stats, kit) => {
      stats.cantidadEquipos++;
      if (!stats.tiposPlanes.includes(kit.TotalPriority)) {
        stats.tiposPlanes.push(kit.TotalPriority);
      }
      if (kit.telemetryData.downlinkThroughputStats.signal)
        stats.equiposActivos++;
      if (kit.PEstandar > 0) stats.equiposDatosEstandar++;
      return stats;
    },
    {
      cantidadEquipos: 0,
      tiposPlanes: [],
      equiposActivos: 0,
      equiposDatosEstandar: 0,
    }
  );
};

export default CalculateSummaryData;
