// React
import React from "react";
// Components
import FormatNumber from "./FormatNumber";

const ProgressBarElement = ({ percentage, totalPlan }) => {
  const usedPercentage = (percentage / totalPlan) * 100;
  const exceedsGB = Math.max(0, percentage - totalPlan);
  const adjustedWidthPriority = Math.min(
    Math.max(usedPercentage - (exceedsGB / totalPlan) * 100, 0),
    100
  );
  const progressBarClass =
    usedPercentage < 50
      ? "bg-success"
      : usedPercentage <= 90
      ? "bg-warning"
      : "bg-danger";

  return (
    <>
      <div className="mb-1">
        <strong className="mb-0">Datos Prioritarios: </strong>
        <span className="margin-right-1">
          {usedPercentage > 100
            ? `100% | ${FormatNumber(Math.round(totalPlan))} GB`
            : `${Math.round(usedPercentage)}% | ${FormatNumber(
                Math.round(percentage)
              )}/${FormatNumber(Math.round(totalPlan))} GB`}
        </span>
      </div>
      <div className="progress mb-1 ms-0 me-3" style={{ width: "100%" }}>
        <div
          className={`progress-bar progress-bar-striped ${progressBarClass}`}
          role="progressbar"
          style={{ width: `${adjustedWidthPriority}%` }}
          aria-valuenow={adjustedWidthPriority}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>

      {usedPercentage > 100 && (
        <div className="mb-1">
          {/* <hr></hr> */}
          <strong style={{ fontSize: 18 }}>Datos Estándar</strong> <br />
          <strong>Adicionales a Prioritarios: </strong>
          <span>{FormatNumber(Math.round(exceedsGB))} GB</span>
          {/* <div className="progress mb-1 ms-0 me-3" style={{ width: `${100}%` }}>
              <div
                className="progress-bar progress-bar-striped bg-danger"
                role="progressbar"
                style={{ width: '100%' }}
                aria-valuenow={Math.min(proportionWidthStandard, 100)}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div> */}
        </div>
      )}
    </>
  );
};

export default ProgressBarElement;
