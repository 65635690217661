// Imports
import { DateTime } from "luxon";
import axios from "axios";

const FetchServiceLines = async (
  serviceLineNumber,
  clienteName,
  cliente_final,
  cliente,
  kitNumber,
  url,
  headers,
  serviceLineLevels
) => {
  const now = DateTime.now();
  const HoursAgo = now.minus({ hours: 10 });
  const formattedEnd_dia = now.toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const formattedStart_dia = now.minus({ months: 2 }).startOf("day").toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const formattedEnd = now.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss");
  const formattedStart = HoursAgo.toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss");
  try {
    const [billingCyclesResponse, telemetryResponse] = await Promise.all([
      axios.get(
        `${url}/api/service_line/${serviceLineNumber}/billingcycles?fini=${formattedStart_dia}&ffin=${formattedEnd_dia}`,
        { headers }
      ),
      axios.get(
        `${url}/api/service_line/${serviceLineNumber}/telemetria_ag?fini=${formattedStart}&ffin=${formattedEnd}`,
        { headers }
      ),
    ]);

    const billingCycles = billingCyclesResponse.data.datos || [];
    const telemetryData = telemetryResponse.data.datos || [];
    const nivel = serviceLineLevels[serviceLineNumber] || 0;

    return {
      [serviceLineNumber]: {
        serviceLines: [
          {
            serviceLineNumber,
            clienteName, //Actualizar
            cliente_final, //Actualizar
            cliente, //Actualizar
            kitNumber,
            telemetryData,
            nivel,
          },
        ],
        billingCycles,
        telemetryData,
        nivel,
      },
    };
  } catch (error) {
    console.error(
      `Error fetching data for account ${serviceLineNumber}:`,
      error
    );
    return { [serviceLineNumber]: { error: "Error fetching serviceLine" } };
  }
};

export default FetchServiceLines;
