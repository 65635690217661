// React
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Core
import Navbar from './core/Navbar';
import Footer from './core/Footer';
import LoadingScreen from './core/LoadingScreen';
// Styles
import './styles/DeviceList.css';
import './styles/Tables.css';
// Imports
import StarlinkDeviceCard from './StarlinkDeviceCard';
import ModalClient from './ModalClient';
import ServiceLineData from './ServiceLineData';
import SummaryTable from './SummaryDashboard';

const DeviceList = () => {
  const url = process.env.REACT_APP_URL_API;
  const token = localStorage.getItem('token');
  const headers = useMemo(() => ({
    'Content-Type': 'application/json',
    'Authorization': token ? `Bearer ${token}` : ''
  }), [token]);

  const navigate = useNavigate();
  const { graphData, summaryData, isLoading, error } = ServiceLineData(url, headers);
  const [expandedRow, setExpandedRow] = useState(null);
  
  const [modalParams, setModalParams] = useState({ KitCliente: '', KitClienteFinal: '', KitName: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [navigate, token]);

  const handleRowClick = (kitNumber) => {
    setExpandedRow(expandedRow === kitNumber ? null : kitNumber);
  };

  const handleClickUpdate = (KitCliente, KitClienteFinal, KitName, ServiceLineNumber) => {
    setModalParams({ KitCliente, KitClienteFinal, KitName, ServiceLineNumber });
    toggleModal();
  };
  
  if (isLoading) return <div><LoadingScreen />;</div>;
  if (error) return <div>{error}</div>;

  return (
    <div style={{ background: '#2c2c2c', minHeight: '100vh', display: 'flex', flexDirection: 'column', width: '100vw' }}>
      <Navbar />
      <div className="dashboard-container-dispositivos shadowcard mt-3 mb-4">
        <SummaryTable data={summaryData} graphData={graphData} />
        <hr style={{ border: '0', borderTop: '3px solid #f8f9fa', width: '100%', margin: '5px 0 5px 0' }} />
        {Object.entries(graphData).map(([serviceLineNumber, serviceLineData]) => (
          <React.Fragment key={serviceLineNumber}>
            <StarlinkDeviceCard
              // Card Data 
              isExpanded={expandedRow === serviceLineNumber}
              handleClick={() => handleRowClick(serviceLineNumber)}
              handleClickUpdate={() => handleClickUpdate(
                serviceLineData.KitCliente || "No Client",
                serviceLineData.KitClienteFinal || "No Final Client",
                serviceLineData.KitName || "No Mnemónico",
                serviceLineData.ServiceLineNumber
              )}
              Nivel={serviceLineData.nivel}
              KitNumber={serviceLineData.KitNumber}
              KitCliente={serviceLineData.KitCliente || "No Client"}
              KitClienteFinal={serviceLineData.KitClienteFinal || "No Final Client"}
              KitName={serviceLineData.KitName || "No Mnemónico"}
              ServiceLineNumber={serviceLineData.ServiceLineNumber}
              AccountNumber={serviceLineData.AccountNumber}
              percentage={serviceLineData.TotalUsage}
              totalPlan={serviceLineData.TotalPriority}
              PPrioritario={parseFloat(serviceLineData.PPrioritario).toFixed(1).toLocaleString('de-DE')}
              PEstandar={parseFloat(serviceLineData.PEstandar).toFixed(1).toLocaleString('de-DE')}
              // Alerta
              signal={serviceLineData.telemetryData.downlinkThroughputStats.signal}
              // signal={serviceLineData.signal}
              alert={'None'}
              // Datos gasto de GB
              GbData={serviceLineData.GbData}
              TitlePriority={'Datos Diarios: '}
              dataKeySubida={'prioritarios'}
              dataKeySubida_2={'estandar'}
              StartDate={serviceLineData.StartDate}
              EndDate={serviceLineData.EndDate}
              MinPriority={parseFloat(serviceLineData.MinPriority).toFixed(0).toLocaleString('de-DE') || "0"}
              MaxPriority={parseFloat(serviceLineData.MaxPriority).toFixed(0).toLocaleString('de-DE') || "0"}
              LastPriority={parseFloat(serviceLineData.LastPriority).toFixed(0).toLocaleString('de-DE') || "0"}
              LimitePriority={serviceLineData.LimitePriority}
              // Downlink Short
              TitleBajada={"Downlink (Últimos 15 min.): "}
              dataKeyBajada={'Mbps'}
              LimiteBajada={serviceLineData.maxValues?.downlinkThroughput || 20}
              downlinkShort={serviceLineData.telemetryData?.downlinkThroughput || []}
              downlinkShortMin={serviceLineData.telemetryData.downlinkThroughputStatsShort.min || "0 Mbps"}
              downlinkShortMax={serviceLineData.telemetryData.downlinkThroughputStatsShort.max || "0 Mbps"}
              downlinkShortAvg={serviceLineData.telemetryData.downlinkThroughputStatsShort.avg || "0 Mbps"}
              downlinkShorLimit={serviceLineData.telemetryData.downlinkThroughputStatsShort.limit || 5}
              // Downlink
              downlinkThroughput={serviceLineData.telemetryData?.downlinkThroughputDetail || []}
              downlinkThroughputDataKey={'Mbps'}
              downlinkThroughputLimit={serviceLineData.telemetryData.downlinkThroughputStats.limit || 5}
              downlinkThroughputMax={serviceLineData.telemetryData.downlinkThroughputStats.max || "0 Mbps"}
              downlinkThroughputMin={serviceLineData.telemetryData.downlinkThroughputStats.min || "0 Mbps"}
              downlinkThroughputAverage={serviceLineData.telemetryData.downlinkThroughputStats.avg || "0 Mbps"}
              // Uplink
              TitleSubida={"Uplink: "}
              uplinkThroughput={serviceLineData.telemetryData?.uplinkThroughput || []}
              uplinkThroughputDataKey={'value'}
              uplinkThroughputLimit={serviceLineData.telemetryData.uplinkThroughputStats.limit || 5}
              uplinkThroughputMin={serviceLineData.telemetryData.uplinkThroughputStats.min}
              uplinkThroughputMax={serviceLineData.telemetryData.uplinkThroughputStats.max}
              uplinkThroughputAverage={serviceLineData.telemetryData.uplinkThroughputStats.avg}
              // PingLatency
              pingLatency={serviceLineData.telemetryData?.pingLatency || []}
              pingLatencyDataKey={'ms'}
              pingLatencyLimit={serviceLineData.telemetryData.pingLatencyStats.limit || 50}
              pingLatencyMin={serviceLineData.telemetryData.pingLatencyStats.min || "0 ms"}
              pingLatencyMax={serviceLineData.telemetryData.pingLatencyStats.max || "0 ms"}
              pingLatencyAverage={serviceLineData.telemetryData.pingLatencyStats.avg || "0 ms"}
              // PingDropRate
              pingDropRate={serviceLineData.telemetryData?.pingDropRate || []}
              pingDropRateDataKey={'ms'}
              pingDropRateLimit={serviceLineData.telemetryData.pingDropRateStats.limit}
              pingDropRateMin={serviceLineData.telemetryData.pingDropRateStats.min || "0 ms"}
              pingDropRateMax={serviceLineData.telemetryData.pingDropRateStats.max || "0 ms"}
              pingDropRateAverage={serviceLineData.telemetryData.pingDropRateStats.avg || "0 ms"}
              // Snr
              snrAvg={serviceLineData.telemetryData?.snrAvg || []}
              snrAvgDataKey={'Porcentaje'}
              snrAvgLimit={serviceLineData.maxValues?.snrAvg}
              snrAvgMin={serviceLineData.telemetryData.snrAvgStats.min || "-%"}
              snrAvgMax={serviceLineData.telemetryData.snrAvgStats.max || "-%"}
              snrAvgAverage={serviceLineData.telemetryData.snrAvgStats.avg || "-%"}
              // Obstruction
              obstructionTime={serviceLineData.telemetryData?.obstructionTime || []}
              obstructionTimeDataKey={'porcentaje'}
              obstructionTimeLimit={serviceLineData.maxValues?.obstructionTime || []}
              obstructionTimeMin={serviceLineData.telemetryData.obstructionTimeStats.min || "0 ms"}
              obstructionTimeMax={serviceLineData.telemetryData.obstructionTimeStats.max || "0 ms"}
              obstructionTimeAverage={serviceLineData.telemetryData.obstructionTimeStats.avg || "0 ms"}
            />
            <hr style={{ border: '0', borderTop: '2px solid #f8f9fa', width: '100%', margin: '5px 0 5px 0' }} />
          </React.Fragment>
        ))}
        <Footer />
      </div>
      <ModalClient
        isOpen={modalOpen}
        toggle={toggleModal}
        Client={modalParams.KitCliente}
        FinalClient={modalParams.KitClienteFinal}
        NickName={modalParams.KitName}
        ServiceLine={modalParams.ServiceLineNumber}
      />
    </div>
  );
};

export default DeviceList;