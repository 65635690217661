// React
import React, { useEffect, useRef } from 'react';
// Echart
import * as echarts from 'echarts';

const CustomChart = ({ 
  title,
  subtitle,
  data, 
  type, 
  dataKey,
  dataKey_2,
  fill, 
  stroke, 
  yAxisUnit,
  yAxisDomain,
  xAxisFontSize,
  margin = { top: 10, right: 30, left: 0, bottom: 0 },
  height,
  cx,
  cy,
  colors = ['#007acc', '#D3D3D3', '#32cd32', '#ffa500', '#6a5acd'],
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    
  const createChartOption = () => {
    // const baseOption = {
      // tooltip: {
      //   trigger: 'axis',
      //   backgroundColor: '#2c2c2c',
      //   borderRadius: 3,
      //   textStyle: { color: 'lightgrey' }
      // },
      // xAxis: {
      //   type: 'category',
      //   data: data.map(item => item.name),
      //   // axisLabel: {
      //   //   fontSize: xAxisFontSize
      //   // }
      // },
      // yAxis: {
      //   type: 'value',
      //   axisLabel: {
      //     formatter: `{value} ${yAxisUnit}`,
      //     fontSize: 8,
      //     color: 'lightgrey'
      //   },
      //   min: yAxisDomain ? yAxisDomain[0] : 'dataMin',
      //   max: yAxisDomain ? yAxisDomain[1] : 'dataMax'
      // }
    // };

    switch (type) {
      case 'area':
        return {
          // ...baseOption,
          title: {
            text: title,
            subtext: subtitle,
            left: 'center',
            textStyle: {
              color: 'lightgrey',
              fontSize: '14px'
            },
            subtextStyle: {
              fontSize: '11px'
            }
          },
          legend: {
            orient: 'horizontal',
            bottom: 'bottom',
            textStyle: {
              color: 'lightgrey',
            },
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#2c2c2c',
            borderRadius: 3,
            textStyle: { color: 'lightgrey' }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map(item => item.name)
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: `{value}`,
              fontSize: 8,
              color: 'lightgrey'
            },
            min: yAxisDomain ? yAxisDomain[0] : 'dataMin',
            max: yAxisDomain ? yAxisDomain[1] : 'dataMax'
          },
          series: [
            {
              name: dataKey,
              type: 'line',
              areaStyle: {},
              data: data.map(item => item[dataKey])
            }
          ]
        };

      case 'bar':
        return {
          series: [{
            type: 'bar',
            data: data.map(item => item[dataKey]),
            itemStyle: { color: fill }
          }]
        };
        
      case 'stackedBar':
        return {
          title: {
            text: title,
            subtext: subtitle,
            left: 'center',
            textStyle: {
              color: 'lightgrey',
              fontSize: '14px'
            },
            subtextStyle: {
              fontSize: '11px'
            }
          },
          xAxis: {
            type: 'category',
            data: data.map(item => item.name),
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: `{value} ${yAxisUnit}`,
              fontSize: 8,
              color: 'lightgrey'
            },
            min: yAxisDomain ? yAxisDomain[0] : 'dataMin',
            max: yAxisDomain ? yAxisDomain[1] : 'dataMax'
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#2c2c2c',
            borderRadius: 3,
            textStyle: { color: 'lightgrey' }
          },
          legend: {
            orient: 'horizontal',
            bottom: 'bottom',
            textStyle: {
              color: 'lightgrey',
            },
          },
          series: [
            {
              name: 'Datos Prioritarios',
              type: 'bar',
              stack: 'ad',
              data: data.map(item => item[dataKey]),
              itemStyle: { color: '#007acc' },
            },
            {
              name: 'Datos Estándar',
              type: 'bar',
              stack: 'ad',
              data: data.map(item => item[dataKey_2]),
              itemStyle: { color: '#32cd32' }
            }
          ]
        };
        
      case 'line':
        return {
          series: [{
            type: 'line',
            data: data.map(item => item[dataKey]),
            itemStyle: { color: stroke || fill }
          }]
        };

      case 'pie':
        return {
          title: {
            text: title,
            left: 'center',
            textStyle: {
              color: 'lightgrey',
            },
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c}',
            backgroundColor: '#2c2c2c',
            textStyle: { color: 'lightgrey' }
          },
          legend: {
            orient: 'horizontal',
            bottom: 'bottom',
            textStyle: {
              color: 'lightgrey',
            },
          },
          series: [{
            name: dataKey,
            type: 'pie',
            center: ['50%', '50%'],
            radius: '50%',
            data: data.map((item, index) => ({
              value: item[dataKey],
              name: item.name,
              itemStyle: { color: colors[index % colors.length] }
            })),
            label: {
              show: true,
              formatter: '{b}: {c}',
              color: 'lightgrey',
              fontSize: 12,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }]
        };
      default:
        // return baseOption;
        return true;
    }
  };
    const chart = echarts.init(chartRef.current);
    const option = createChartOption();
    chart.setOption(option);

    const handleResize = () => {
      chart.resize();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      chart.dispose();
      window.removeEventListener('resize', handleResize);
    };
  }, [
    data, type, colors, dataKey, dataKey_2, fill, stroke, subtitle, title, yAxisDomain, yAxisUnit
  ]);


  return (
    <div>
      <div ref={chartRef} style={{ width: '100%', height: height || 300 }} />
    </div>
  );
};

export default CustomChart;